
import Routers from './Routers';
import {useState, useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from './language/Converter';

// styles
import './App.css';
import { CSpinner } from '@coreui/react'
import './assets/css/style.css';
import './assets/css/auth.css';
import './assets/css/responsive.css';
import '@coreui/coreui/dist/css/coreui.min.css'

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <>

      <Router>
        <Routers />
      </Router>

      {/*/----- react toaster -----/*/}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

    
    </>
  );
}

export default App;
