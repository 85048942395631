import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { CFormInput, CForm, CFormLabel, CButton } from '@coreui/react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

// images
import logo from '../assets/images/logo.png';

function UpdatePassword() {

  const { tokenId } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  // Forgot Password API Integration
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleUpdatePassword = async () => {
    try {
      const language = localStorage.getItem("language");
      const response = await axios.post(`${apiUrl}/reset-password`, {
        password: password,
        confirm_password: confirmPassword,
        token: tokenId
      },{
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'accept-language': language
        }
      });
  
      console.log(response.data);
        
      if (response.data.result === 'success') {
        console.log('Password updated successfully');
        navigate(`/login`);
        toast.success(response.data.message);
      }
        
    } catch (error) {
      console.error('Error logging in:', error.response.data.message);
      toast.error(error.response.data.message);
    }
    
  }

  return (
    <>
      {/* <!-- Auth Section -- Start Here --> */}
      <section className="auth-section-wrapper forgot-password-wrapper">
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol md="7">
              <div className='site-logo'>
                <Link to='/'>
                  <img src={logo} alt='logo' />
                </Link>
              </div>
            </CCol >
            <CCol md="5">
              <div className="auth-form-wrapper">
                <div className="app-logo-wrapper">
                  <h2>{t('Reset Password')}</h2>
                </div>
                <div className="auth-form-wrapper">
                  <CForm>
                    <div className="single-input-wrapper">
                      <CFormLabel  className="d-block">{t('Password')}</CFormLabel >
                      <CFormInput 
                        type="password" 
                        name="" 
                        placeholder={t('Enter Password')}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                      />
                    </div>
                    <div className="single-input-wrapper mt-4">
                      <CFormLabel  className="d-block">{t('Confirm Password')}</CFormLabel >
                      <CFormInput 
                        type="password" 
                        name="" 
                        placeholder={t('Confirm Password')}
                        value={confirmPassword}
                        onChange={(e)=>setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div className="login-btn-wrapper mt-4">
                      <CButton color="warning" onClick={handleUpdatePassword}>{t('Reset Password')}</CButton>
                    </div>
                  </CForm>
                </div>
              </div>
            </CCol>
          </CRow>
        </CContainer>

        {/*/----- react toaster -----/*/}
        
      </section>
      {/* <!-- Auth Section -- End's Here --> */}
    </>
  )
}

export default UpdatePassword;
