import React, { useEffect, useState } from "react";
import { CContainer, CRow, CCol, CButton } from "@coreui/react";
import { CTable, CTableBody, CTableRow, CTableDataCell } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import VideoPlayer from "../components/VideoPlayer";
import UserMedia from "../components/UserMedia";
import axios from "axios";
import { toast } from "react-toastify";

function Profile() {

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { userid } = useParams();
  const loggedInUserId = localStorage.getItem('userid');
  const language = localStorage.getItem("language");
  console.log("User ID:", userid);
  console.log("Loged in User ID:", loggedInUserId);

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);  

  const handleNavigate = () => {
    if(isLoggedIn)
    {
      if (loggedInUserId === userid) {
        toast.error("You can't pitch your own profile!");
      }else{
        navigate(`/pitch-profile/${userid}`);
      }
    }else{
      toast.error(t('Please login before pitching!'));
    }
  }

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  //---- fetch Upcoming Events
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const language = localStorage.getItem("language");
      try {
        const response = await fetch(`${apiUrl}/event/upcoming`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'accept-language': language
          },
          body: JSON.stringify({
            user_id: userid,
          }),
        });

        const data = await response.json();
        setUpcomingEvents(data.data);

        if (!response.ok) {
          console.log(data.message);
        }

        if (data.result === "success") {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEvents();
  }, []);

  //---- fetch Today Events
  const [todayEvents, setTodayEvents] = useState([]);
  useEffect(() => {
    const fetchTodayEvents = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const language = localStorage.getItem("language");
      try {
        const response = await fetch(`${apiUrl}/event/today`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'accept-language': language
          },
          body: JSON.stringify({
            user_id: userid,
          }),
        });

        const data = await response.json();
        setTodayEvents(data.data);

        if (!response.ok) {
          console.log(data.message);
        }

        if (data.result === "success") {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTodayEvents();
  }, []);

  // ---- fetch user
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //--- profile picture update handling
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  const [aboutTxt, setAboutTxt] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  //--- video update handling
  const [profileVideo, setProfileVideo] = useState(null);
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;

  //---- Fetching Roles
  const roleOptions = userRoles.map((role) => ({
    value: role._id,
    label: role.name,
  }));

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const language = localStorage.getItem("language");
      try {
        const response = await fetch(`${apiUrl}/roles`, {
          method: "GET",
          headers:{
            'accept-language': language
          }
        });

        const data = await response.json();
        setUserRoles(data.data);

        if (!response.ok) {
          console.log("Network response was not ok");
          toast.error(data.message);
        }

        if (data.result === "success") {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);


  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState("");
  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        {
          user_id: userid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            'accept-language': language
          },
        }
      );

      if (response.data.result === "success") {
        setUserData(response.data.data);
        console.log("User data fetched successfully:", response.data.data);

        console.log(response.data.message);
        setProfilePicture(
          `${imgeUrl}/${response.data.data.userDetail.profile_image}`
        );
        setProfileVideo(
          `${imgeUrl}/${response.data.data.userDetail.profile_video}`
        );
        console.log(response.data.data.userDetail.profile_video);
        setProfilePictureUrl(
          `${imgeUrl}/${response.data.data.userDetail.profile_image}`
        );

        setAboutTxt(response.data.data.userDetail.about);
        setSelectedRoles(response.data.data.userDetail.roles);

        setUserName(response.data.data.first_name +" " + response.data.data.sir_name);
      } else {
        throw new Error(response.data.message || "Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);

      toast.error(`${error.message}`);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      {/*/--- navbar ---/*/}
      <Navbar />

      {/* bio section */}
      <section className="user-profile-wrapper">
        <CContainer>
          <CRow>
            <CCol lg={4}>
              <div className="profile-banner">
                <div className="profile-banner-wrapper">
                  <div className="profile-banner-wrapper-content">
                    <div className="user-profile-picture text-center mx-auto">
                      {profilePictureUrl && (
                        <img src={profilePictureUrl} alt="Profile" />
                      )}
                    </div>
                    <div className="user-bio">
                      <h2 className="user-name">{userName}</h2>
                      <div className="user-role-wrapper">
                        <div>
                          <ul className="user-profile-roles-wrapper text-center">
                            {selectedRoles.map((role, index) => (
                              <li
                                key={index}
                                className="user-profile-roles-show"
                              >
                                {role.name}
                              </li> // Adjust depending on your role object structure
                            ))}
                          </ul>
                        </div>
                        
                      </div>
                    </div>
                    <div className="user-awards mt-5">
                      {/* <div className="award-info mx-auto">
                        <div className="awards-icon">
                          <div className="awards-desc"></div>
                        </div>
                      </div> */}
                      <div className="text-center award-add-btn">
                        <p className="mb-0">{t('Awards')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol lg={8}>
              <div className="user-profile-clip-wrap mt-4 mt-lg-0">
                <VideoPlayer videoUrl={profileVideo} width="100%" />
              </div>

              {userid === loggedInUserId?
                <></>
                :
                <div className="mb-4">
                  <CButton
                    className="primary_outline_btn"
                    onClick={handleNavigate}
                  >
                    {t('pitch your vision')}
                  </CButton>
                </div>
              }

              <div className="user-about">
                <h3>{t('About')}</h3>
                <p>{aboutTxt}</p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- event section ---/*/}

      <section className="event-wrapper">
        <CContainer>
          <CRow>
            <CCol md={6} xs={6}>
              <p className={"event_title"}>
                {t('My Upcoming Event')}
              </p>
            </CCol>
            <CCol lg={12}>
                {/* //----- table for uncoming events */}
                <div className="table-wrapper upcoming mt-5">
                  <CTable responsive>
                    <CTableBody>
                      {upcomingEvents.map((event) => (
                        <CTableRow key={event._id}>
                          <CTableDataCell>
                            <span>{t('with')} {event.user_id.first_name}</span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span>{event.datetime}</span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span>{t('for')} "{event.film_name}"</span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <Link>{event.link}</Link>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      <UserMedia />
     
      {/*/--- Footer ---/*/}
      <Footer />

      {/*/--- copy right ---/*/}
      <CopyRight />
    </>
  );
}

export default Profile;
