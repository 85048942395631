import React from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import PackageCard from '../components/PackageCard';

function Subscription() {

    const { t } = useTranslation();

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            <section className='home-banner subscribe-banner'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12}>
                            <div className='home-banner-wrapper text-center'>
                                <h2>
                                    {t('Join THE film community')}<br/>
                                    {t('subscribe now')}
                                </h2>
                                <p>{t('Connect with Industry Professionals!')}</p>
                                <CButton className='explore-btn mt-5'>{t('Explore')}</CButton>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- banner section ---/*/}
            <section className='subscription-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12}>
                            <div className='subscription-wrapper-content text-center'>
                                <h2 className='section-heading text-center'>{t('START YOUR')}</h2>
                                <h3 className='section-sub-heading text-center'>{t('MEMBERSHIP')}</h3>
                                <p className='section-txt'>
                                    {t("Cinewoo: Empowering Canada's Film Community.")}<br/>
                                    {t('Subscribe Today and Be One of the First 300 for a Free Year of Community Membership')}
                                </p>
                            </div>
                            <PackageCard durationTxt={t('Community Members')} time={t('Enjoy a Free Year')} subscribe={true} />
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default Subscription;
