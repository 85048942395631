import { useState } from 'react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UserCalls({ callId, onDelete,title,dateLocation,details,job,role,onEdit,description,datetime }) {

    const { t } = useTranslation();
    const [menuListOpen, setMenuListOpen] = useState(false);
    const navigate = useNavigate();
    const handleMenuList = () => {
        setMenuListOpen(!menuListOpen);
    }
    const handleDelete = () => {
        if(window.confirm('Are you sure you want to delete this casting call?')) {
            onDelete(callId); 
        }
    }

    const handleEdit = () => {
        const dateOnly = datetime.split("T")[0];
        onEdit({
            _id: callId,
            tag: job,
            roles: role.split(", ").map(r => ({ name: r })), // Assuming roles are passed as a string of names
            audition_detail: details,
            location: dateLocation,
            datetime: dateOnly, // Pass only the date part
            description: description
        });
    };

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleDateString();
    };


  return (
    <>
      <div className='avail-casting-call-wrapper position-relative'>
        <div className='edit-calls-menu'>
            <Link onClick={handleMenuList}>
                <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#D9D9D9"/>
                    <circle cx="21" cy="4" r="4" fill="#D9D9D9"/>
                    <circle cx="38" cy="4" r="4" fill="#D9D9D9"/>
                </svg>
            </Link>
        </div>
        {menuListOpen ?
            <div className='edit-calls-menu-list'>
                <Link  onClick={handleEdit}>{t('Edit')}</Link>
                <Link onClick={handleDelete}>{t('Delete')}</Link>
            </div>
            :
            <></>
        }
        <div className='avail-casting-call-header'>
            <h3><Link to={`/user-auditions/${callId}`}>{title}</Link></h3>
            <h4>{role}</h4>
        </div>
        <div className='avail-casting-call-body'>
            <h5>{job}</h5>
            <p>
               {details}
            </p>
            <p>{description}</p>
        </div>
        <div className='avail-casting-call-footer'>
            <h5>{t('Dates & Locations')}</h5>
            <p>{formatDate(datetime)}, {dateLocation}</p>
        </div>
      </div>
    </>
  )
}

export default UserCalls;
