import React, { useState } from "react";
import playbtn from '../assets/images/play-btn.png';
import ReactPlayer from "react-player";
import { useTranslation } from 'react-i18next';

const VideoPlayer = ({ videoUrl, width, height, title, videoSrc, userProfile, link, btnContent }) => {
    const { t } = useTranslation();
    const [playing, setPlaying] = useState(false);

    const togglePlayPause = () => {
        setPlaying(!playing);
    };

  return (
    <>
        <div className='talent-frame-wrapper profile-clip mt-0 mb-3'>
            <div className='video-frame position-relative'>
                <div className="video-player-container edit-video-player">
                    
                    <ReactPlayer
                        className="edit-video-profile"
                        url={videoUrl}
                        playing={playing}
                        controls={playing}
                        width={width}
                        height={height}
                        onPause={() => setPlaying(false)}
                    />

                    {!playing && (
                        <div className="edit-profile-clip-caption">
                            <button className="play-button m-0" onClick={togglePlayPause}>
                                <img src={playbtn} alt="play"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
  );
};

export default VideoPlayer;
