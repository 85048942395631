import React, { useState } from 'react';
import { COffcanvas, COffcanvasBody, COffcanvasHeader, CCloseButton } from '@coreui/react';
import { CButton } from '@coreui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function ProfileSidebar() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const [userLanguage, setUserLanguage] = useState('');
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);

    const handleNavigate = () => {
        navigate('/casting-calls-creation');
    }
    
    const handleNavigate2 = () => {
        navigate('/user-film-booth');
    }

    const handleNavigate3 = () => {
        navigate('/edit-user-profile');
    }

    const handleNavigate4 = () => {
        navigate('/membership');
    }

    const isActive = (path) => {
        return location.pathname.includes(path);
    }


    const handleLogout = async () => {
        const language = localStorage.getItem("language");
        if (language === 'english') {
            setUserLanguage('en');
        }else{
            setUserLanguage('fr');
        }
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept-language': userLanguage
                }
            };
            const response = await fetch(`${apiUrl}/logout`, config);
            console.log('Logout successful', response);
            localStorage.removeItem('token');
            navigate('/login');
        } catch (error) {
            console.error('Logout failed', error.response || error);
        }
    }
    

    return (
        <>
            <div className='edit-profile-sidebar'>
                <CButton className="d-lg-none w-100 profile-sidebar-toggler" onClick={() => setVisible(true)}> {t('Side Menu')} »</CButton>
                <COffcanvas  responsive="lg" expand="lg" dark placement="start" visible={visible} onHide={() => setVisible(false)} backdrop={false}>
                    <COffcanvasHeader className='justify-content-end'>
                        <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
                    </COffcanvasHeader>
                    <COffcanvasBody>
                        <div className='profile-edit-controls'>
                            <CButton 
                                className={isActive('/edit-user-profile') ? 'primary_outline_btn' : 'secondary_outline_btn'}  
                                onClick={handleNavigate3}
                            >
                                {t('Profile')}
                            </CButton>
                            <CButton 
                                className={isActive('/casting-calls-creation') ? 'primary_outline_btn' : 'secondary_outline_btn'} 
                                onClick={handleNavigate}
                            >
                                {t('Create Casting Call')}
                            </CButton>
                            <CButton 
                                className={isActive('/user-film-booth') ? 'primary_outline_btn' : 'secondary_outline_btn'}
                                onClick={handleNavigate2}
                            >
                                {t('Film Pitching Booth')}
                            </CButton>
                            <CButton 
                                className={isActive('/membership') ? 'primary_outline_btn' : 'secondary_outline_btn'} 
                                onClick={handleNavigate4}
                            >
                                {t('My Membership')}
                            </CButton>
                            <CButton className='primary_outline_btn'  onClick={handleLogout}>{t('Logout')}</CButton>
                        </div>
                    </COffcanvasBody>
                </COffcanvas>
            </div>
        </>
    )
}

export default ProfileSidebar;
