import React, { useEffect, useState } from 'react';
import { CRow, CCol, CButton } from '@coreui/react';
import { CModal, CModalBody } from '@coreui/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function PackageCard(props) {

    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [isSubscribed, setIsSubscribed] = useState(false);
    const language = localStorage.getItem("language");

    useEffect(() => {
        const token = localStorage.getItem('token');

        fetch(`${apiUrl}/subscription`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'accept-language': language
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Your Already Subscribed") {
                setIsSubscribed(true); 
                toast.info(t('You are already subscribed!')); 
            } else if (data.data) {
                setSubscription(data.data);
            }
        })
        .catch(error => console.error('Failed to fetch subscription data:', error));
    }, [apiUrl]);

    const handleSubscribe = () => {
        const token = localStorage.getItem('token');
        if (!isSubscribed) {
            fetch(`${apiUrl}/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'accept-language': language
                },
                body: JSON.stringify({ price: 0, duration: 'monthly' })
            })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Your Already Subscribed") {
                    setIsSubscribed(true); 
                    toast.info(data.message);
                } else {
                    toast.success(t('You are subscribed!'));
                    setIsSubscribed(true);
                    setSubscription(data.data); 
                }
            })
            .catch(error => {
                console.error('Failed to subscribe:', error);
                toast.error(t('Subscription failed!'));
            });
        }
    };

  return (
    <>
        <div className='monthly-package-wrapper'>
            <CRow>
                <CCol lg={5} className='mx-auto'>
                    <div className='monthly-package-content'>
                        <div className='monthly-package-card-body'>
                            <div className='top-circle'></div>
                            <div className='package-name mt-5'>
                                {/* <h2>0.00<span>$</span></h2> */}
                                <h2>{subscription && subscription.price ? `${subscription.price.toFixed(2)}$` : "0.00$"}</h2>
                                    <h3 className='duration-text'>{subscription ? subscription.duration : t('Monthly')}</h3>
                                {/* <h3>Monthly</h3> */}
                              
                            </div>
                            <div className='package-duration mt-5'>
                                <h4 className='mb-3'>{t('1 Year Free Membership')}</h4>
                                <p><span className='sterik'>*</span>  {t('First')} <span>300</span> {props.durationTxt}</p>
                                <p>{props.time}</p>
                            </div>
                            <CButton className='primary_outline_btn mt-5 mb-3' onClick={() => setVisible(!visible)} disabled={isSubscribed}>{t('Subscribe')}</CButton>
                        </div>
                    </div>
                </CCol>
            </CRow>
        </div>

            {/* subscribe modal */}
            <CModal
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="VerticallyCenteredExample"
                className='subscrition-modal'
                size='lg'
            >
                <CModalBody>
                    <CButton className='close_modal_btn' onClick={() => setVisible(false)}></CButton>
                    <div className='subscrition-modal-body-content d-flex flex-column align-items-center justify-content-center'>
                        <h2>{t('Subscribe')}<br/>{t('Now')}</h2>
                        <p>
                            {t('Be Among the Elite 300: Enjoy a Year of Free Community Membership with cinewoo')}
                        </p>
                        <CButton className='subscribe-btn mt-4' onClick={handleSubscribe} disabled={isSubscribed} >{t('Subscribe')}</CButton>
                    </div>
                </CModalBody>
                
            </CModal>
    </>
  )
}

export default PackageCard;
