import React from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import ProfileSidebar from '../components/ProfileSidebar';
import PackageCard from '../components/PackageCard';

function Membership() {

    const { t } = useTranslation();
    const navigate = useNavigate();
   
    const handleNavigate = () => {
        navigate('/subscription');
    }
    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            <CContainer>
                <CRow>
                    <CCol lg={2}>
                        <ProfileSidebar />
                    </CCol>
                    <CCol lg={10} >
                        <div className='membership-wrapper'>
                            <CRow>
                                <CCol lg={10} className='mx-auto'>
                                    <PackageCard durationTxt={t('community members receive')} />
                                    <div className='d-flex w-100'>
                                        <div className='w-50 p-2'>
                                            <CButton className='secondary_outline_btn w-100'>{t('Cancel')}</CButton>
                                        </div>
                                        <div className='w-50 p-2'>
                                            <CButton className='primary_btn w-100' onClick={handleNavigate}>{t('Upgrade Now')}</CButton>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        

        </>
    )
}

export default Membership;
