import React, { useState, useEffect } from "react";
import { CContainer, CRow, CCol, CButton, CForm } from "@coreui/react";
import { CTable, CTableBody, CTableRow, CTableDataCell } from "@coreui/react";
import { CModal, CModalBody } from "@coreui/react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import VideoPlayer from "../components/VideoPlayer";
import ProfileSidebar from "../components/ProfileSidebar";
import EditUserMedia from "../components/EditUserMedia";

//---- images
import editImg from "../assets/images/edit-btn.png";
import award from "../assets/images/award-icon.svg";
import addBtn from '../assets/images/add-btn.svg';
import axios from "axios";
import Spinner from "../components/loader/Spinner";

function EditProfile() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userid = localStorage.getItem("userid");
  const language = localStorage.getItem("language");
  //---- modals visibility states
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoloading, setvideoLoading] = useState(false);

  const [editInput, setEditInput] = useState(false);
  const handleEditInput = () => {
    setEditInput(!editInput);
  };

  //--- profile picture update handling
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true); // Start loading
      setProfilePicture(selectedFile); // Store the file
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // Set the URL for the profile picture to the result from FileReader
        setProfilePictureUrl(reader.result);
        setLoading(false); // Stop loading
      };
  
      reader.readAsDataURL(selectedFile);
    }
  };

  //--- about text update handling
  const [editAbout, setEditAbout] = useState(false);
  const handleAboutTxt = () => {
    setEditAbout(!editAbout);
  };

  const [aboutTxt, setAboutTxt] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  //--- video update handling
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [profileVideo, setProfileVideo] = useState(null);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
      setvideoLoading(true); // Start loading
  
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileVideo(event.target.result);
        setvideoLoading(false); // Stop loading
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    console.log("profileVideo : ", profileVideo);
    setVisible2(!visible2);
  };
  //--- end video update handling

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;

  //---- Fetching Roles
  const roleOptions = userRoles.map((role) => ({
    value: role._id,
    label: role.name,
  }));

  const handleRoleChange = (selectedOptions) => {
    setSelectedRoles(selectedOptions); // Ensure it's always an array
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const language = localStorage.getItem("language");
        console.log('roles language : ', language);
        const response = await fetch(`${apiUrl}/roles`, {
          method: "GET",
          headers:{
            'accept-language': language
          }
        });

        if (!response.ok) {
          console.log("Network response was not ok");
        }

        const data = await response.json();
        setUserRoles(data.data);

        if (data.result === "success") {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleProfileUpdate = async () => {
    setEditAbout(false);
    setEditInput(false);
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("about", aboutTxt);
      if (profilePicture) {
        formData.append("profile_image", profilePicture); // Ensure it's the file being appended
      }
      if (selectedVideo) {
        formData.append("profile_video", selectedVideo);
      }
      if (Array.isArray(selectedRoles)) {
        selectedRoles.forEach((role) => {
          formData.append("roles", role.value);
        });
      } else {
        console.error("selectedRoles is not an array:", selectedRoles);
      }

      const response = await fetch(`${apiUrl}/update-profile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'accept-language': language
        },
        body: formData,
      });

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.result === "success") {
        fetchUserData();
        console.log("User Update Successfully");
        toast.success(responseData.message);
      } else {
        toast.error(responseData.message || "Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // toast.error("Error updating profile");
    }
  };

  //-------- end edit profile API integration --------//

  //-------- create event API integration --------//
  const [eventName, setEventName] = useState("");
  const [filmName, setFilmName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventDayTime, setEventDayTime] = useState("");

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setEventDayTime(selectedDate + ' ' + eventDayTime.split(' ')[1]);
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setEventDayTime(eventDayTime.split(' ')[0] + ' ' + selectedTime);
  };

  const handleAddEvent = async () => {
    setLoading(true);
    console.log('event date and time : ', eventDayTime);
    if (eventName === '' || filmName === '' || eventLink === '' || eventDayTime === '') {
      toast.error('Please Fill in All required fields!');
    }else{
      setVisible(!visible);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/event`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            'accept-language': language
          },
          body: JSON.stringify({
            name: eventName,
            film_name: filmName,
            link: eventLink,
            datetime: eventDayTime,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          console.log("Faild to create event!", data.message);
          toast.error(data.message);
        }

        if (data.result === "success") {
          console.log("Event added Successfully");
          toast.success(data.message);
          fetchEvents();
          setLoading(false);
        }
      } catch (error) {
        console.error("Error creating event:", error);
      }
    }
    setLoading(false);
  };
  // ------ end create event -------//

  //---- fetch Upcoming Events ----//
  const [upcomingEvents, setUpcomingEvents] = useState([]);

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/event/upcoming`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'accept-language': language
          },
          body: JSON.stringify({
            user_id: userid,
          }),
        });

        const data = await response.json();
        setUpcomingEvents(data.data);
        setLoading(false);

        if (!response.ok) {
          console.log("Network response was not ok ", data.message);
        }

        if (data.result === "success") {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    useEffect(() => {
      fetchEvents();
    }, [apiUrl]);

  //---- fetch Today Events ----//
  const [todayEvents, setTodayEvents] = useState([]);
  useEffect(() => {
    const fetchTodayEvents = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/event/today`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'accept-language': language
          },
          body: JSON.stringify({
            user_id: userid,
          }),
        });

        const data = await response.json();
        setTodayEvents(data.data);
        setLoading(false);

        if (!response.ok) {
          console.log("Network response was not ok ", data.message);
        }

        if (data.result === "success") {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchTodayEvents();
  }, []);

  //------- get user data --------//
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState("");
  const [roleset, setroledate] = useState([]);
  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userid");

    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            'accept-language': language
          },
        }
      );

      if (response.data.result === "success") {
        setUserData(response.data.data);
        console.log("User data fetched successfully:", response.data.data);

        console.log(response.data.message);
        if(response.data.data.userDetail){
          setProfilePicture(
            `${imgeUrl}/${response.data.data.userDetail.profile_image}`
          );

          setProfileVideo(
            `${imgeUrl}/${response.data.data.userDetail.profile_video}`
          );

          console.log(response.data.data.userDetail.profile_video);

          setProfilePictureUrl(
            `${imgeUrl}/${response.data.data.userDetail.profile_image}`
          );
          
          setAboutTxt(response.data.data.userDetail.about);
          
          const userRoleIds = response.data.data.userDetail.roles.map(
            (role) => role._id
          );

          const selectedRoleOptions = userRoles
            .filter((role) => userRoleIds.includes(role._id))
            .map((role) => ({
              value: role._id,
              label: role.name,
            }));
          setSelectedRoles(selectedRoleOptions);
          setroledate(response.data.data.userDetail.roles);
          console.log("Selected Roles:", selectedRoleOptions);
        }
      
        setUserName(response.data.data.first_name +" " + response.data.data.sir_name);
      } else {
        throw new Error(response.data.message || "Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);


  const handleCancel = () => {
    setEditInput(false);
    setEditAbout(false);
    fetchUserData();
  }
  return (
    <>
      {/*/--- navbar ---/*/}
      <Navbar />

      <CContainer>
        <CRow>
          <CCol lg={2}>
            <ProfileSidebar />
          </CCol>
          <CCol lg={10}>
            <div className="page-wrapper position-relative">
              {/* bio section */}
              <section className="user-profile-wrapper">
                <CContainer>
                  <CForm>
                    <CRow>
                      <CCol lg={4}>
                        <div className="profile-banner">
                          <div className="profile-banner-wrapper">
                            <div className="profile-banner-wrapper-content">
                              <div className="user-profile-picture text-center mx-auto">
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  <>
                                  {profilePictureUrl && (
                                    <img src={profilePictureUrl} alt="Profile" />
                                  )}
                                  </>
                                )}
                                <label
                                  htmlFor="fileInput"
                                  className={`edit-profile-picture d-flex`}
                                >
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_2215_6603)">
                                      <path
                                        d="M29.589 9.366L19.62 19.336C18.746 20.209 17.211 21.353 16.124 21.94L14.083 23.042C13.632 23.286 13.231 23.404 12.86 23.404C12.353 23.404 11.915 23.174 11.659 22.772C11.336 22.265 11.359 21.608 11.727 20.82L12.763 18.604C13.292 17.47 14.401 15.887 15.287 15.001L25.256 5.031L29.589 9.366ZM31.041 7.915L31.097 7.859C32.298 6.659 32.302 4.709 31.107 3.514C29.953 2.359 27.922 2.365 26.763 3.525L26.707 3.58L31.041 7.915ZM25.07 28.327C25.072 27.76 24.615 27.299 24.048 27.296L1.03101 27.189H1.02701C0.462007 27.189 0.00300654 27.646 6.53956e-06 28.211C-0.00199346 28.778 0.455007 29.24 1.02201 29.242L24.039 29.348H24.044C24.609 29.349 25.068 28.892 25.07 28.327Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2215_6603">
                                        <rect
                                          width="32"
                                          height="32"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </label>
                                <input
                                  id="fileInput"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                              <div className="user-bio w-100">
                                <h2 className="user-name">{userName}</h2>
                                <div className="user-role-wrapper">
                                    <ul className="user-profile-roles-wrapper">
                                    {roleset.map((role, index) => (
                                        <li
                                          key={index}
                                          className="user-profile-roles-show"
                                        >
                                          {role.name}
                                        </li> // Adjust depending on your role object structure
                                      ))}
                                    </ul>
                              
                                  {editInput ? (
                                    <div className="pt-5">
                                      <Select
                                        isMulti
                                        name="roles"
                                        className="user-profile-roles-input"
                                        options={userRoles.map((role) => ({
                                          value: role._id,
                                          label: role.name,
                                        }))}
                                        value={selectedRoles}
                                        onChange={handleRoleChange}
                                        placeholder="Select roles"
                                      />
                                    </div>
                                    ) : (
                                      <p></p>
                                    )}

                                  <Link
                                    className={`edit-role d-flex`}
                                    onClick={handleEditInput}
                                  >
                                    <svg
                                      width="21"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_2229_6614)">
                                        <path
                                          d="M18.9931 5.85387L12.7625 12.0851C12.2163 12.6307 11.2569 13.3457 10.5775 13.7126L9.30188 14.4014C9.02 14.5539 8.76938 14.6276 8.5375 14.6276C8.22063 14.6276 7.94688 14.4839 7.78688 14.2326C7.585 13.9157 7.59938 13.5051 7.82938 13.0126L8.47688 11.6276C8.8075 10.9189 9.50063 9.9295 10.0544 9.37575L16.285 3.1445L18.9931 5.85387ZM19.9006 4.947L19.9356 4.912C20.6863 4.162 20.6888 2.94325 19.9419 2.19637C19.2206 1.4745 17.9513 1.47825 17.2269 2.20325L17.1919 2.23762L19.9006 4.947ZM16.1688 17.7045C16.17 17.3501 15.8844 17.062 15.53 17.0601L1.14438 16.9932H1.14188C0.788754 16.9932 0.501879 17.2789 0.500004 17.632C0.498754 17.9864 0.784379 18.2751 1.13875 18.2764L15.5244 18.3426H15.5275C15.8806 18.3432 16.1675 18.0576 16.1688 17.7045Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2229_6614">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                            transform="translate(0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Link>
                                </div>
                              </div>
                              <div className="user-awards mt-5">
                                {/* <div className="award-info mx-auto">
                                  <div className="awards-icon">
                                    <img src={award} alt="award"/>
                                    <div className="awards-desc-wrapper">
                                      <div className="position-relative">
                                        <div className="award-title">Award Title</div>
                                        <div className="awards-desc">
                                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </div>
                                        <div className="award-pointer"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="text-center award-add-btn">
                                  <p className="d-inline mb-0">{t('Awards')}</p>
                                  <Link onClick={() => setVisible3(!visible3)}>
                                    <img src={addBtn} alt="add"/>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                      <CCol lg={8}>
                        <div className="user-about-wrapper">
                          <div className="user-bio-video-clip mt-lg-0 mt-5">
                            {/* {loading && <Spinner />} */}
                            {videoloading ? (
                              <Spinner />
                            ) : (
                              <VideoPlayer videoUrl={profileVideo} width="100%" />
                            )}

                            <Link
                              className="edit-bio-video-clip"
                              onClick={() => setVisible2(!visible2)}
                            >
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_2014_3158)">
                                  <path
                                    d="M38.8356 12.2925L25.7513 25.3781C24.6041 26.5239 22.5894 28.0254 21.1628 28.7958L18.4839 30.2422C17.892 30.5625 17.3657 30.7173 16.8788 30.7173C16.2133 30.7173 15.6384 30.4155 15.3024 29.8878C14.8785 29.2224 14.9087 28.3601 15.3917 27.3258L16.7514 24.4173C17.4458 22.929 18.9013 20.8513 20.0642 19.6884L33.1485 6.60277L38.8356 12.2925ZM40.7413 10.388L40.8148 10.3145C42.3911 8.73952 42.3964 6.18014 40.8279 4.6117C39.3133 3.09577 36.6476 3.10364 35.1264 4.62614L35.0529 4.69833L40.7413 10.388ZM32.9044 37.1788C32.907 36.4346 32.3072 35.8295 31.563 35.8256L1.3532 35.6851H1.34795C0.606384 35.6851 0.00394608 36.285 8.58318e-06 37.0265C-0.00261642 37.7707 0.597196 38.3771 1.34138 38.3797L31.5512 38.5188H31.5578C32.2993 38.5201 32.9018 37.9203 32.9044 37.1788Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2014_3158">
                                    <rect width="42" height="42" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Link>
                          </div>
                          <div className="user-about">
                            <h3>{t('About')}</h3>
                            {editAbout ? (
                              <textarea
                                placeholder="Write About user"
                                value={aboutTxt}
                                onChange={(e) => setAboutTxt(e.target.value)}
                              ></textarea>
                            ) : (
                              <p>{aboutTxt}</p>
                            )}

                            <div className="edit-about-wrapper text-lg-end mt-4">
                              <CButton className="secondary_btn me-2" onClick={handleCancel}>
                                {t('Cancel')}
                              </CButton>
                              <CButton
                                className="primary_btn"
                                onClick={handleProfileUpdate}
                              >
                                {t('Save')}
                              </CButton>
                            </div>
                          </div>
                          <Link
                            className="edit-about-btn"
                            onClick={handleAboutTxt}
                          >
                            <img src={editImg} alt="edit" />
                            <span>{t('Edit')}</span>
                          </Link>
                        </div>
                      </CCol>
                    </CRow>
                  </CForm>
                </CContainer>
              </section>

              {/*/--- event section ---/*/}
              <section className="event-wrapper">
                <CContainer>
                  <CRow>
                    <CCol md={6} xs={6}>
                      <p
                        className={ "event_title"}
                      >
                        {t('My Upcoming Event')}
                      </p>
                    </CCol>
                    <CCol lg={12}>
                      {/* //----- table for uncoming events */}
                      <div className="table-wrapper mt-5">
                        <CTable responsive>
                          <CTableBody>
                            {upcomingEvents.map((event) => (
                              <CTableRow key={event._id}>
                                <CTableDataCell>
                                  <span>{t('with')} {event.user_id.first_name}</span>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>{event.datetime}</span>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>{t('for')} "{event.film_name}"</span>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <Link to={event.link}>{event.link}</Link>
                                </CTableDataCell>
                              </CTableRow>
                            ))}
                          </CTableBody>
                        </CTable>
                      </div>
                     
                      <div className="text-end add-event-wrapper">
                        <CButton
                          className="primary_btn mt-4"
                          onClick={() => setVisible(!visible)}
                        >
                          {t('Add Event')}
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CContainer>
              </section>

              {/*/--- media section ---/*/}
              <EditUserMedia editProfile={true}/>
            </div>
          </CCol>
        </CRow>
      </CContainer>

      {/*/---- Footer ----/*/}
      <Footer />

      {/*/---- copy right ----/*/}
      <CopyRight />

      {/*/---- modals to add event ----/*/}
      <CModal
        className="photos-upload-modal"
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
        size="lg"
      >
        <CModalBody>
          <div className="upload-photo-modal-body-content film-upload-content text-center">
            <CForm>
              <h3>{t('Add Event Details')}</h3>
              <div className="w-100 d-flex">
                <div className="w-50 p-2">
                  <input
                    type="text"
                    placeholder={t('Event Name')}
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                  />
                </div>

                <div className="w-50 p-2">
                  <input
                    type="text"
                    placeholder={t('Film Name')}
                    value={filmName}
                    onChange={(e) => setFilmName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="w-100 p-2">
                  <input
                    type="text"
                    placeholder={t('Enter Link')}
                    value={eventLink}
                    onChange={(e) => setEventLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="w-50 p-2">
                  <input
                    type="date"
                    placeholder=""
                    value={eventDayTime.split(' ')[0]}
                    onChange={handleDateChange}
                  />
                </div>

                <div className="w-50 p-2">
                  <input
                    type="time"
                    placeholder=""
                    value={eventDayTime.split(' ')[1]}
                    onChange={handleTimeChange}
                  />
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="w-50 mt-4 p-2">
                  <CButton
                    className="secondary_outline_btn w-100"
                    onClick={() => setVisible(!visible)}
                  >
                    {t('Cancel')}
                  </CButton>
                </div>
                <div className="w-50 mt-4 p-2">
                  <CButton
                    className="primary_btn w-100"
                    onClick={handleAddEvent}
                  >
                    {t('Save')}
                  </CButton>
                </div>
              </div>
            </CForm>
          </div>
          <CButton
            className="close_modal_btn"
            onClick={() => setVisible(false)}
          ></CButton>
        </CModalBody>
      </CModal>

      {/* modals to update video */}
      <CModal
        className="photos-upload-modal"
        alignment="center"
        visible={visible2}
        onClose={() => setVisible2(false)}
        aria-labelledby="VerticallyCenteredExample"
        size="lg"
      >
        <CModalBody>
          <div className="upload-photo-modal-body-content film-upload-content text-center">
            <h3>{t('upload your video')}</h3>
            <div className="text-center mt-4">
              <label htmlFor="photoInput" className="secondary_outline_btn">
                {t('Browse')}
              </label>
              <input
                type="file"
                id="photoInput"
                accept="video/*"
                onChange={handleVideoChange}
                hidden
              />
            </div>
            <div className="mt-4">
              <CButton className="primary_btn" onClick={handleSave}>
                {t('Save')}
              </CButton>
            </div>
          </div>
          <CButton
            className="close_modal_btn"
            onClick={() => setVisible2(false)}
          ></CButton>
        </CModalBody>
      </CModal>

      {/*/---- modals to add awards ----/*/}
      <CModal
        className="photos-upload-modal"
        alignment="center"
        visible={visible3}
        onClose={() => setVisible3(false)}
        aria-labelledby="VerticallyCenteredExample"
        size="lg"
      >
        <CModalBody>
          <div className="upload-photo-modal-body-content film-upload-content text-center">
            <CForm>
              <h3>{t('Add Awards')}</h3>
              <div className="w-100">
                <input
                  type="text"
                  placeholder={t('Award Title')}
                />
              </div>
              <div className="w-100 mt-4">
                <textarea
                  rows={6}
                  placeholder={t('Description')}
                />
              </div>
              <div className="w-100 d-flex">
                <div className="w-50 mt-4 p-2">
                  <CButton
                    className="secondary_outline_btn w-100"
                    onClick={() => setVisible3(!visible3)}
                  >
                    {t('Cancel')}
                  </CButton>
                </div>
                <div className="w-50 mt-4 p-2">
                  <CButton
                    className="primary_btn w-100"
                  >
                    {t('Save')}
                  </CButton>
                </div>
              </div>
            </CForm>
          </div>
          <CButton
            className="close_modal_btn"
            onClick={() => setVisible3(false)}
          ></CButton>
        </CModalBody>
      </CModal>

    </>
  );
}

export default EditProfile;
