import React from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';

function Cinematheque() {

    const { t } = useTranslation();

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            <section className='home-banner video-hub-banner'>
                <div className='video-banner-layer'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='home-banner-wrapper text-center'>
                                    <h2 className='typing-demo2'>
                                        {t('Stay Tuned')}
                                    </h2>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default Cinematheque;
