import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

//*//----- auth pages -----//*//
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import ForgotPassword from './auth/ForgotPassword';
import UpdatePassword from './auth/UpdatePassword';

//*//----- landing pages -----//*//
import NewTalent from './pages/NewTalent';
import CastingCalls from './pages/CastingCalls';
import RoleDescription from './pages/RoleDescription';
import CastingAudition from './pages/CastingAudition';
import FilmPitchingBooth from './pages/FilmPitchingBooth';
import SuccessStories from './pages/SuccessStories';
import IndustryProfiles from './pages/IndustryProfiles';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import CastingCallsCreation from './pages/CastingCallsCreation';
import UserFilmPitchingBooth from './pages/UserFilmPitchingBooth';
import Membership from './pages/Membership';
import Subscription from './pages/Subscription';
import Home from './pages/Home';
import Auditions from './pages/Auditions';
import VideoHub from './pages/VideoHub';
import Cinematheque from './pages/Cinematheque';
import PitchProfile from './pages/PitchProfile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ContactUs from './pages/ContactUs';


function Routers() {

  function isAuthenticated() {
    // You would replace this with the actual logic to check for a token
    const token = localStorage.getItem('token');
    return token != null;
  }
  
  // Higher-Order Component for Protected Routes
  function PrivateRoute({ children }) {
    const auth = isAuthenticated(); // Check if the user is authenticated
    return auth ? children : <Navigate to="/login" />; // Redirect to login screen if not authenticated
  }
  

  return (
    <>
      <Routes>
        {/*........... auth pages .........*/}
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:tokenId' element={<UpdatePassword />} />

        {/*........... landing pages .........*/}
        <Route path='/' element={<Home />} />
        <Route path='/casting-room' element={<NewTalent />} />
        <Route path='/casting-calls' element={<CastingCalls />} />
        <Route path='/role-description/:id' element={<RoleDescription />} />
        <Route path='/casting-audition/:castingCallId' element={<CastingAudition />} />
        <Route path='/pitch-profile/:castingCallId' element={<PitchProfile />} />
        <Route path='/film-booth' element={<FilmPitchingBooth />} />
        <Route path='/success-stories' element={<SuccessStories />} />
        <Route path='/industry-profiles' element={<IndustryProfiles />} />
        <Route path='/user-profile/:userid' element={<Profile />} />
        <Route path='/video-hub' element={<VideoHub />} />
        <Route path='/cinematheque' element={<Cinematheque />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-use' element={<TermsOfUse />} />
        <Route path='/contact-us' element={<ContactUs />} />
       
        {/*/----- Protected routes -----/*/}
        <Route path='/edit-user-profile' element={
          <PrivateRoute>
            <EditProfile />
          </PrivateRoute>
          } 
        />
        <Route path='/casting-calls-creation' element={
          <PrivateRoute>
            <CastingCallsCreation />
          </PrivateRoute>} 
        />
        <Route path='/user-film-booth' element={
          <PrivateRoute>
            <UserFilmPitchingBooth />
          </PrivateRoute>} 
        />
        <Route path='/user-auditions/:id' element={
          <PrivateRoute>
            <Auditions />
          </PrivateRoute>} 
        />
        <Route path='/membership' element={ 
          <PrivateRoute>
            <Membership />
          </PrivateRoute>} 
        />
        <Route path='/subscription' element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        }/>
        
      </Routes>
    </>
  )
}

export default Routers;
