import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { CFormInput, CForm, CFormLabel, CButton } from '@coreui/react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// images
import logo from '../assets/images/logo.png';

function ForgotPassword() {

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const navigate = useNavigate();

  // Forgot Password API Integration
  const [email, setEmail] = useState('');
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const handleForgotPassword = async () => {
    if (!validateEmail(email)) {
      toast.error('Enter a valid email address');
    } else {
      try {
        const language = localStorage.getItem("language");
        const response = await axios.post(`${apiUrl}/forgot-password`, {
          email: email,
        },{
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'accept-language': language
          }
        });
  
        console.log(response.data);
        
        if (response.data.result === 'success') {
          console.log('Email Send successfully');
          // navigate(`/update-password`);
          toast.success(response.data.message);
        }
        
      } catch (error) {
          console.error('Error sending email:', error.message);
          toast.error(error.message);
      }
    }
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <>
      {/* <!-- Auth Section -- Start Here --> */}
      <section className="auth-section-wrapper forgot-password-wrapper">
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol md="7">
              <div className='site-logo'>
                <Link to='/'>
                  <img src={logo} alt='logo' />
                </Link>
              </div>
            </CCol >
            <CCol md="5">
              <div className="auth-form-wrapper">
                <div className="app-logo-wrapper">
                  <h2>{t('Forgot Password')}</h2>
                </div>
                <div className="auth-form-wrapper">
                  <CForm>
                    <div className="single-input-wrapper">
                      <CFormLabel  className="d-block">{t('Email')}</CFormLabel >
                      <CFormInput 
                        type="text" 
                        name="" 
                        placeholder={t('Enter Your Email')}
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </div>
                    <div className="login-btn-wrapper mt-4">
                      <CButton color="warning" onClick={handleForgotPassword}>{t('Submit')}</CButton>
                    </div>
                  </CForm>
                </div>
              </div>
            </CCol>
          </CRow>
        </CContainer>

        {/*/----- react toaster -----/*/}
        
      </section>
      {/* <!-- Auth Section -- End's Here --> */}
    </>
  )
}

export default ForgotPassword;
