import React, {useState, useEffect} from 'react';
import { CContainer, CRow, CCol, CForm, CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import contactBanner from '../assets/images/contact-banner.png';

function ContactUs() {

    const { t } = useTranslation();
    const [roles, setRoles] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const language = localStorage.getItem("language");

    //------- Fetching all Roles
    const [userRoles, setUserRoles] = useState([]);
    const roleOptions = userRoles.map(role => ({
        value: role._id,
        label: role.name
    }));

    const handleRoleChange = (selectedOptions) => {
        const selectedRoleIds = selectedOptions.map(option => option.value);
        setRoles(selectedRoleIds);
    };

    //------ fetching roles 
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiUrl}/roles`,{
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    }
                });

                const data = await response.json();
                setUserRoles(data.data);

                if (!response.ok) {
                    console.log('Network response was not ok', data.message);
                }

                if (data.result === 'success') {
                    console.log(data.message);
                }
                
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            <section className='home-banner contact-us-banner'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12}>
                            <div className='home-banner-wrapper text-center'>
                                <h2 className=''>
                                    {t('Contact Us')}
                                </h2>
                                <p>{t("We're here to help!  Please reach out to us using the form below ")}</p>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- contact section ---/*/}
            <section className='contact-us-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12} lg={4}>
                            <div className='contact-us-img-wrapper'>
                                <img src={contactBanner} alt='contact us'/>
                            </div>
                        </CCol>
                        <CCol md={12} lg={8}>
                            <div className='contact-form-wrapper mt-4 mt-lg-0'>
                                <h3>{t('Contact Us')}</h3>
                                <p className='mt-3'>{t('Any Question ? We Would Be Happy To Help You !')}</p>
                                <CForm className='contact-us-form'>
                                    <CRow>
                                        <CCol lg={6}>
                                            <div className='w-100'>
                                                <input 
                                                    type='text' 
                                                    placeholder={t('Name')}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol lg={6}>
                                            <div className='w-100'>
                                                <input 
                                                    type='text' 
                                                    placeholder={t('Email')}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol lg={6}>
                                            <div className='w-100'>
                                                <input 
                                                    type='text' 
                                                    placeholder={t('Subject')}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol lg={6}>
                                            <div className='w-100' style={{marginTop: '35px'}}>
                                                <Select
                                                    isMulti
                                                    name="roles"
                                                    options={roleOptions}
                                                    className="select-input roles-select-wrapper"
                                                    classNamePrefix="select"
                                                    placeholder={t('Select Roles')}
                                                    onChange={handleRoleChange}
                                                    value={roleOptions.filter(option => roles.includes(option.value))}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol lg={12}>
                                            <div className='w-100'>
                                                <textarea
                                                    rows={6}
                                                    placeholder={t('Description')}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol lg={12}>
                                            <div className='w-100 mt-5'>
                                                <CButton className='primary_btn w-100'>{t('Submit')}</CButton>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default ContactUs;
