import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation  } from 'react-router-dom';
import  { 
        CNavbar, 
        CNavbarNav,
        CForm, 
        CNavLink, 
        CNavItem,
        CCollapse,
        CNavbarToggler,
        CNavbarBrand,
        CContainer
    } from '@coreui/react';
import logo from '../assets/images/main-logo.png';
import ReactFlagsSelect from "react-flags-select";
import i18n from '../language/Converter';
import { useTranslation } from 'react-i18next';

function Navbar() {
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();
    // const [selected, setSelected] = useState('US');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selected, setSelected] = useState(() => {
      const language = localStorage.getItem('language');
      return language === 'fr' ? 'FR' : 'US';
    });

    const isActive = (path) => {
        return location.pathname === path;
    };

    useEffect(() => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token);
    }, []);  
    
    useEffect(() => {
      const language = localStorage.getItem('language');
      i18n.changeLanguage(language);
    }, []);

    function handleClick(lang) {
      setSelected(lang);
      console.log(selected);
      
      localStorage.setItem('language', lang);
      
      const language = localStorage.getItem('language');
      i18n.changeLanguage(language);
      console.log('language nav', language);
    }

    const language = localStorage.getItem('language');

    return (
      <>
        <CNavbar expand="lg" className="header-navbar">
          <CContainer>
            <CNavbarBrand as={NavLink} to="/">
              <img src={logo} alt="logo"/>
            </CNavbarBrand>
            <CNavbarToggler onClick={() => setVisible(!visible)} />
            <CCollapse className="navbar-collapse" visible={visible}>
              <CNavbarNav className='mx-auto'>
                <CNavItem>
                  <CNavLink as={Link} to="/casting-room" className={isActive('/casting-room') ? 'active' : ''}>
                    Casting Room
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink as={Link} to="/film-booth" className={isActive('/film-booth') ? 'active' : ''}>Film Pitching Booth</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink as={Link} to="/video-hub" className={isActive('/video-hub') ? 'active' : ''}>Video HUB</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink as={Link} to="/cinematheque" className={isActive('/cinematheque') ? 'active' : ''}>Cinematheque</CNavLink>
                </CNavItem>
              </CNavbarNav>
              <CForm className="d-flex">
                {/* country flags */}
                {language === 'fr'?
                  <Link className='language-input ms-3 ms-lg-0' onClick={(e)=>handleClick('en')}>English</Link>
                :
                  <Link className='language-input ms-3 ms-lg-0' onClick={(e)=>handleClick('fr')}>Français</Link>
                }
                {/* <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => {
                    handleClick(code);
                  }}
                  selectButtonClassName="menu-flags-button"
                  className="menu-flags"
                  countries={["US","FR"]}
                  customLabels={{ 
                    US: "EN", 
                    FR: "FR"
                  }}
                /> */}
                {/* country flags */}
                <Link to={isLoggedIn? '/edit-user-profile' : '/login'} className='mx-2 profile-link'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path className='nav_icons' d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path className='nav_icons' d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Link>
              </CForm>
            </CCollapse>
          </CContainer>
        </CNavbar>
      </>
    )
}

export default Navbar;
