import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import ProfileSidebar from '../components/ProfileSidebar';
import SimpleVideoPlayer from '../components/SimpleVideoPlayer';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Auditions() {

    const { t } = useTranslation();
    const { id } = useParams();
    const [auditions, setAuditions] = useState([]);
    const imgeUrl = process.env.REACT_APP_IMAGE_URL;
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchAuditions = async () => {
            try {
                const language = localStorage.getItem("language");
                const response = await fetch(`${apiUrl}/casting-call/auditions/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'accept-language': language
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setAuditions(data.data);
                } else {
                    console.error('Failed to fetch auditions:', data.message);
                }
            } catch (error) {
                console.error('Error fetching auditions:', error);
            }
        };

        if (id) {
            fetchAuditions();
        }
    }, [apiUrl, id]);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            <CContainer>
                <CRow className='gx-3'>
                    <CCol lg={2}>
                        <ProfileSidebar />
                    </CCol>
                    <CCol lg={10} >
                        <div className='page-wrapper position-relative'>
                            <div className='page-heading-wrapper'>
                                <h2 className='page-heading'>{t('Auditions')}</h2>
                            </div>
                        </div>
                        <div className='user-film-booth-wrapper'>
                            <CRow>
                            {auditions.map((audition, index) => (
                                    <CCol key={index} lg={4} md={6}>
                                        <SimpleVideoPlayer 
                                            videoUrl={`${imgeUrl}/${audition.audition} `}
                                            width='100%'
                                            height='100%'
                                            userProfile={true}
                                            btnContent={t('View Profile')}
                                            link={`/user-profile/${audition.user_id}`} 
                                        />
                                    </CCol>
                                ))}
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default Auditions;
