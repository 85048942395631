import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import playBtn from '../assets/images/play.png';
import poster from '../assets/images/poster3.png';
import { Link } from 'react-router-dom';
import Spinner from './loader/Spinner';

const SimpleVideoPlayer = ({ videoUrl, width, height, title, videoSrc, userProfile, link, btnContent,profilelink }) => {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePlayPause = () => {
    setPlaying(!playing);
    if (!playing) setLoading(true);
  };
  const handleReady = () => {
    setLoading(false); // Not needed if we handle loading via onStart
  };

  const handleStart = () => {
    setLoading(false); // Hide spinner when video starts
  };

  return (
    <div className='talent-frame-wrapper profile-clip mt-4'>
      <div className='video-frame position-relative'>
        <div className="video-player-container">
       
          <ReactPlayer
            url={videoUrl}
            playing={playing}
            controls={playing}
            width={width}
            height={height}
            onPause={() => setPlaying(false)}
          />
           
          {!playing && (
            <button className="play-button m-0" onClick={togglePlayPause}>
              <img src={playBtn} alt="Play Button" />
            </button>
          )}
         
        </div>
      </div>
     
      <div className="d-flex justify-content-between">
        <div className='video-desc'>
          <div className='video-title mt-lg-3 mt-2'><Link to={profilelink}>{title}</Link></div>
          <div className='video-src'>{videoSrc}</div>
        </div>
        {userProfile ? 
          <div className='video-link'>
            <Link to={link}>
              <button className="primary_outline_btn mt-3">{btnContent}</button>
            </Link>
          </div>
        :
          <></>
        }
      </div>
    </div>
  );
};

export default SimpleVideoPlayer;
