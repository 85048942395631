import React from 'react';
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { useTranslation } from 'react-i18next';

function MultiStepProgressBar(props) {

  const { t } = useTranslation();

  return (
    <>
      <ProgressBar
        percent={((props.step - 1) * 100)/2}
        filledBackground="#E0E0E0"
      >
        <Step>
          {({ accomplished }) => (
            <div className={`step ${accomplished ? 'completed' : ''}`}>
                <div className='step-initializer'>
                    1
                </div>
                <p>{t('Personal Details')}</p>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <div className={`step ${accomplished ? 'completed' : ''}`}>
                <div className='step-initializer'>
                    2
                </div>
                <p>{t('Final')}</p>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <div className={`step ${accomplished ? 'completed' : ''}`}>
                <div className='step-initializer'>
                    3
                </div>
                <p>{t('Abjective Details')}</p>
            </div>
          )}
        </Step>
      </ProgressBar>
    </>
  )
}

export default MultiStepProgressBar;
