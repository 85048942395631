import React, { useEffect, useState } from "react";
import { CContainer, CRow, CCol, CButton } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import SuccessStoryCard from "../components/SuccessStoryCard";
import ProfileCard from "../components/ProfileCard";

//--------- images
import profile from "../assets/images/profile1.png";
import discover from "../assets/images/discover.png";
import axios from "axios";
import Spinner from "../components/loader/Spinner";
import bannerBg from '../assets/images/profile-banner.png';

function FilmPitchingBooth() {

  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProfiles();
    fetchStories();
  }, []);


  //---- fetching success stories
  const fetchStories = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem("language");
      const response = await axios.get(`${apiUrl}/success-stories`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accept-language': language
        }
      });
      setStories(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching success stories:', error);
    }
    setLoading(false);
  };

  //---- fetching users
  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem("language");
      const response = await axios.get(`${apiUrl}/users`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accept-language': language
        }
      });
      if (response.data && Array.isArray(response.data.data)) {
        const profileData = response.data.data.map((user) => {
          const imgSrc = user.userDetail?.profile_image
            ? `${imgeUrl}/${user.userDetail.profile_image}`
            : `${profile}`;
          // console.log(imgSrc);

          return {
            ...user,
            imgSrc,
          };
        });

        setProfiles(profileData);
        setLoading(false);
      } else {
        console.error("Unexpected API response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
    setLoading(false);
  };

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/success-stories");
  };
  const handleNavigate2 = () => {
    navigate("/industry-profiles");
  };

  const scrollToSection = () => {
    const castingCallsSection = document.getElementById('prifles-wrapper');
    if (castingCallsSection) {
      castingCallsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/*/--- navbar ---/*/}
      <Navbar />

      {/*/--- banner section ---/*/}
      <section className="banner-wrapper" style={{backgroundImage: `url(${bannerBg})`}}>
         <div className="h-100-p"> {/* banner-wraper-inner-layer */}
          <CContainer className="h-100-p">
            <CRow className="align-items-center h-100-p">
              <CCol md={12}>
                <div className="banner-content text-center">
                  <h2>
                    {t('pitch your')}
                    <br />
                    {t('film')}
                  </h2>
                  <p>{t('Enter Our Film Pitching Booth Now')}</p>
                  <CButton className="explore-btn mt-5" onClick={scrollToSection}>{t('Enter')}</CButton>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </section>

      {/*/--- profile cards ---/*/}
      <section className="profile-wrapper" id='prifles-wrapper'>
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol md={12}>
              <div className="profile-content">
                <h2>{t('Pitch now')}</h2>
                <h4>{t('Select profile')}</h4>
              </div>
            </CCol>
            <CCol md={12}>
              <div className="profile-content-cards">
                
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {profiles.slice(0, 5).map((user, index) => (
                      <ProfileCard
                        key={user._id}
                        userid={user._id}
                        title={`${user.first_name}`}
                        imgSrc={user.imgSrc}
                        link={`/user-profile/${user._id}`}
                      />
                    ))}
                  </>
              )}
              </div>
            </CCol>
            <CCol md={12}>
              <div className="text-center">
                <CButton className="primary_btn" onClick={handleNavigate2}>
                  {t('show all')}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- success stories ---/*/}
      <section className="success-stories-wrapper">
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol md={12}>
              <div className="success-stories-content mb-5">
                <h2 className="section-heading">{t('success')}</h2>
                <h3 className="section-sub-heading">{t('stories')}</h3>
              </div>
            </CCol>
          
              {loading ? (
                <Spinner />
                ) : (
                <>
                  {stories.slice(0, 2).map(story => (
                    <CCol md={12} lg={6}>
                        <SuccessStoryCard
                          key={story._id}
                          imgSrc={`${imgeUrl}/${story.image}`}
                          title={story.name}
                          description={story.description}
                          location={story.location}
                          datetime={story.datetime}
                        />
                    </CCol>
                  ))}
                </>
              )}
     
            <CCol md={12}>
              <div className="text-center mt-5">
                <CButton className="primary_btn" onClick={handleNavigate}>
                  {t('show all')}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- how it works section ---/*/}
      <section className="how-it-works-wrapper">
        <CContainer className="h-100-p">
          <CRow>
            <CCol md={6}>
              <div className="work-process">
                <h2>{t('how it works?')}</h2>
                <div className="process-step">
                  <div>
                    <span>{t('Browse expert profiles:')} </span>
                    {t('Explore a diverse array of industry professionals ready elevate your vision.')}
                  </div>
                </div>
                <div className="process-step">
                  <div>
                    <span>{t('Pitch in 60 seconds:')} </span>
                    {t('Craft your compelling vision through a concise video presentation.')}
                  </div>
                </div>
                <div className="process-step">
                  <div>
                    <span>{t('Get feedback from pros:')} </span>
                    {t('Receive valuable insights and guidance to refine your pitch and propel your project forward.')}
                  </div>
                </div>
              </div>
            </CCol>
            <CCol md={6}>
              <h2 className="discover-heading">{t("Let's kick things off")}</h2>
              <div className="discover-wrapper">
                <img src={discover} alt="discover" />
                <CButton className="primary_btn">{t('Discover More')}</CButton>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- Footer ---/*/}
      <Footer />

      {/*/--- copy right ---/*/}
      <CopyRight />
    </>
  );
}

export default FilmPitchingBooth;
