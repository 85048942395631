import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import ProfileSidebar from '../components/ProfileSidebar';
import SimpleVideoPlayer from '../components/SimpleVideoPlayer';

function UserFilmPitchingBooth() {

    const { t } = useTranslation();
    const [filmPitchings, setFilmPitchings] = useState([]); 
    const apiUrl = process.env.REACT_APP_API_BASE_URL; 
    const videoBaseUrl = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {
        const fetchFilmPitchings = async () => {
            try {
                const token = localStorage.getItem('token');
                const language = localStorage.getItem("language");
                const response = await fetch(`${apiUrl}/filmpitching`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setFilmPitchings(data.data); 
                    console.log(data.data);
                } else {
                    console.error('Failed to fetch film pitchings:', data.message);
                }
            } catch (error) {
                console.error('Error fetching film pitchings:', error);
            }
        };

        fetchFilmPitchings();
    }, [apiUrl]);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            <CContainer>
                <CRow className='gx-3'>
                    <CCol lg={2}>
                        <ProfileSidebar />
                    </CCol>
                    <CCol lg={10} >
                        <div className='page-wrapper position-relative'>
                            <div className='page-heading-wrapper'>
                                <h2 className='page-heading'>{t('Film Pitching Booth')}</h2>
                            </div>
                        </div>
                        <div className='user-film-booth-wrapper'>
                            <CRow>
                                {filmPitchings.map((film, index) => (
                                    <CCol key={index} lg={4} md={6}>
                                        <SimpleVideoPlayer 
                                            title={`${film.pitch_user_by.first_name} ${film.pitch_user_by.sir_name}`} // Display the user's first and last name
                                            videoUrl={`${videoBaseUrl}/${film.pitch_video}`} // Full path to the video
                                            width='100%'
                                            height='100%'
                                            userProfile={true}
                                            btnContent={t('View Vision')}
                                        />
                                    </CCol>
                                ))}
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default UserFilmPitchingBooth;
