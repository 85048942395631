import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol, CButton, CForm } from '@coreui/react';
import { CNav, CNavLink, CTabPane, CNavItem, CTabContent } from '@coreui/react';
import { CModal, CModalBody,  } from '@coreui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

//-------- components
import MediaCard from './MediaCard';
import Spinner from './loader/Spinner';


function EditUserMedia(props) {

    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(1);
    const userid = localStorage.getItem("userid");
    //--- modals visibility handling states
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [loadingFilms, setLoadingFilms] = useState(false);

    //--- upload thumbnail
    const [thumbnail, setThumbnail] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setThumbnail(e.target.result);
         // Stop loading
        };
        reader.readAsDataURL(file);
        document.getElementById('thumbnailLabel').innerText = file.name;
    };

    //------ Create Film API integration ------//
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const imgeUrl = process.env.REACT_APP_IMAGE_URL;

    const [filmTitle, setFilmTitle] = useState('');

    const handleAddFilm = async () => {
        setLoadingFilms(true);
        
        if (filmTitle === '' || thumbnail === null) {
            toast.error('Please Fill in All required fields!');
        }else{
            setVisible2(!visible2);
            try {
                const language = localStorage.getItem("language");
                const token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append('title', filmTitle);
                
                // Convert data URL to Blob
                const thumbnailBlob = await fetch(thumbnail).then((res) => res.blob());
                formData.append('thumbnail', thumbnailBlob, 'thumbnail.jpg');

                const response = await fetch(`${apiUrl}/film`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    },
                    body: formData
                });

                const responseData = await response.json();

                if (!response.ok) {
                    console.log('Failed to add film');
                    toast.error(responseData.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }

                if (responseData.result === 'success') {
                    console.log('Film added Successfully');
                    toast.success(responseData.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    fetchFilms();
                    setLoadingFilms(false);
                }

            } catch (error) {
                console.error('Error creating film:', error.message);
            }
        }
        setLoadingFilms(false);
    }

    //------ Upload Photos API integration ------//
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(files);
        const photosElement = document.getElementById('photos_uploaded');
        photosElement.innerHTML = "";

        files.forEach(file => {
            const listItem = document.createElement('li');
            listItem.textContent = file.name;
            photosElement.appendChild(listItem);
        });
    };

    const handlePhotosUpload = async () => {
        setLoadingPhotos(true);
        const token = localStorage.getItem('token');
        const language = localStorage.getItem("language");
        console.log('selectedImages : ',selectedImages);

        const formData = new FormData();
        selectedImages.forEach((image) => {
            formData.append('photos', image);
        });
        
        if ([...formData.entries()].length === 0) {
            toast.error('Please browse at least one photo!');
        }else{
            setVisible(!visible);
            try {
                const response = await fetch(`${apiUrl}/photo`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    },
                    body: formData,
                });

                const data = await response.json();
                if (response.ok) {
                    toast.success(data.message);
                    fetchPhotos();
                    console.log('Response:', data);
                    setSelectedImages([]);
                } else {
                    console.error('Upload failed!');
                    toast.error(data.message);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
        setLoadingPhotos(false);
    }

    //------ Fetch Photos API integration ------//
    const [galleryPhotos, setGalleryPhotos] = useState([]);

    const fetchPhotos = async () => {
        setLoadingPhotos(true);
        const language = localStorage.getItem("language");
        try {
            const response = await fetch(`${apiUrl}/photos`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'accept-language': language
                },
                body: JSON.stringify({
                    user_id: userid,
                }),
            });
            if (!response.ok) {
                console.log(data.message);
            }

            const data = await response.json();
            setGalleryPhotos(data.data);

            if (data.result === 'success') {
                console.log(data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
          setLoadingPhotos(false);
    };

    useEffect(() => {
        fetchPhotos();
    }, []);

    //------ Fetch Films API integration ------//
    const [galleryFilms, setGalleryFilms] = useState([]);
 
        const fetchFilms = async () => {
            setLoadingFilms(true);
          try {
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/films`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'accept-language': language
                },
                body: JSON.stringify({
                    user_id: userid,
                }),
            });

            if (!response.ok) {
                console.log(data.message);
            }

            const data = await response.json();
            setGalleryFilms(data.data);
            setLoadingFilms(false);

            if (data.result === 'success') {
                console.log(data.message);
            }

          } catch (error) {
            console.error('Error fetching data:', error);
          }
          setLoadingFilms(false);
        };
    useEffect(() => {
        fetchFilms();
    }, []);

    const handleDeletePhoto = async (photoId) => {
        // Call fetchPhotos again to refresh the gallery after deletion
        fetchFilms();
        fetchPhotos();
        console.log(`Deleted photo with id: ${photoId}`);
    };

    const handleDeleteFlim = async (photoId) => {
        // Call fetchPhotos again to refresh the gallery after deletion
        fetchFilms();
        console.log(`Deleted photo with id: ${photoId}`);
    };

    return (
        <>
            <section className='media-wrapper'>
                <CContainer>
                    <CRow>
                        <CCol lg={12}>
                            <div className='media-tabs'>
                                <CNav variant="tabs" role="tablist">
                                    <CNavItem>
                                        <CNavLink
                                            href="#!"
                                            active={activeKey === 1}
                                            onClick={() => setActiveKey(1)}
                                        >
                                            {t('Photos')}
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink
                                            href="#!"
                                            active={activeKey === 2}
                                            onClick={() => setActiveKey(2)}
                                        >
                                            {t('Films')}
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                                <CTabContent>
                                    <CTabPane role="tabpanel" aria-labelledby="photos-tab" visible={activeKey === 1}>
                                        {/* tab content for photos */}
                                        <div className='media-tabs-wrapper'>
                                            <CRow>
                                           
                                            {loadingPhotos ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                {galleryPhotos.map((photo, index) => (
                                                    <CCol key={index} lg={2} md={3} xs={6}>
                                                        {/* <MediaCard 
                                                            imgSrc={`${imgeUrl}/${photo.photo}`} 
                                                            clsname='d-none'
                                                        /> */}
                                                        <MediaCard
                                                            imgSrc={`${imgeUrl}/${photo.photo}`} 
                                                            mediaId={photo._id}
                                                            clsname="photo-classname d-none"
                                                            title="Photo Title"
                                                            // onDelete={(id) => console.log(`Deleted photo with id: ${id}`)}
                                                            onDelete={handleDeletePhoto}
                                                            mediaType="photo"
                                                            editProfile= {props.editProfile}
                                                            />
                                                        </CCol>
                                                    ))}
                                                </>
                                            )}
                                            </CRow>
                                            {/* <div className='media-left-layer'></div>
                                            <div className='media-right-layer'></div> */}
                                        </div>
                                        {props.editProfile ?
                                            <div className='text-center upload-gallery py-5'>
                                                <CButton className='primary_btn' onClick={() => setVisible(!visible)}>{t('Upload Photos')}</CButton>
                                            </div>
                                        :
                                            <></>
                                        }
                                    </CTabPane>
                                    <CTabPane role="tabpanel" aria-labelledby="films-tab" visible={activeKey === 2}>
                                        {/* tab content for films */}
                                        <div className='media-tabs-wrapper'>
                                            <CRow>
                                      
                                                {galleryFilms.map((film, index) => (
                                                    <CCol key={index} lg={2} md={3} xs={6}>
                                                        <MediaCard
                                                            imgSrc={`${imgeUrl}/${film.thumbnail}`}
                                                            mediaId={film._id}
                                                            clsname="film-classname d-block"
                                                            title={film.title}
                                                            onDelete={handleDeleteFlim}
                                                            mediaType="film"
                                                            editProfile= {props.editProfile}
                                                        />
                                                    </CCol>
                                                ))}
                                            </CRow>
                                            {/* <div className='media-left-layer'></div>
                                            <div className='media-right-layer'></div> */}
                                        </div>

                                        {props.editProfile ?
                                            <div className='text-center upload-gallery py-5'>
                                                <CButton className='primary_btn' onClick={() => setVisible2(!visible2)}>{t('Upload Films')}</CButton>
                                            </div>
                                        :
                                            <></>
                                        }
                                    </CTabPane>
                                </CTabContent>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
                
            </section>

            {/*/ modals to upload photos /*/}
            <CModal
                className='photos-upload-modal'
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalBody>
                    <div className='upload-photo-modal-body-content text-center'>
                        <h3>{t('upload your Photo')}</h3>
                        <CForm>
                            <div className='text-center mt-4'>
                                <label htmlFor='photoInput' className='secondary_outline_btn'>
                                    {t('Browse')}
                                </label>
                                <input 
                                    type='file' 
                                    id="photoInput"
                                    multiple 
                                    onChange={handleImageChange} 
                                    hidden
                                />
                                <ul id='photos_uploaded'></ul>
                            </div>
                            <div className='mt-4'>
                                <CButton 
                                    className='primary_btn' 
                                    onClick={handlePhotosUpload}
                                >
                                    {t('Save')}
                                </CButton>
                            </div>
                        </CForm>
                    </div>
                    <CButton className='close_modal_btn' onClick={() => setVisible(false)}></CButton>
                </CModalBody>
            </CModal>

            {/* modals to upload films */}
            <CModal
                className='photos-upload-modal'
                alignment="center"
                visible={visible2}
                onClose={() => setVisible2(false)}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalBody>
                    <div className='upload-photo-modal-body-content film-upload-content text-center'>
                        <h3>{t('Add film')}</h3>
                        <CForm>
                            <div className='w-100 d-flex'>
                                <div className='w-50 p-2'>
                                    <input 
                                        type='text' 
                                        placeholder={t('Film Title')}
                                        value={filmTitle}
                                        onChange={(e)=>setFilmTitle(e.target.value)}
                                    />
                                </div>

                                <div className='w-50 p-2'>
                                    <label htmlFor='thumbnail'>{t('Upload Thumbnail')}</label>
                                    <input 
                                        id='thumbnail' 
                                        type='file' 
                                        placeholder={t('Upload Thumbnail')}
                                        hidden 
                                        accept='image/*' 
                                        
                                        onChange={handleFileChange}
                                    />
                                    <p id='thumbnailLabel'></p>
                                </div>
                            </div>
                            <div className='w-100 d-flex'>
                                <div className='w-50 mt-4 p-2'>
                                    <CButton className='secondary_outline_btn w-100' onClick={()=>setVisible(!visible2)}>
                                        {t('Cancel')}
                                    </CButton>
                                </div>
                                <div className='w-50 mt-4 p-2'>
                                    <CButton className='primary_btn w-100' onClick={handleAddFilm}>
                                        {t('Save')}
                                    </CButton>
                                </div>
                            </div>
                        </CForm>
                    </div>
                    <CButton className='close_modal_btn' onClick={() => setVisible2(false)}></CButton>
                </CModalBody>
            </CModal>
                        
        </>
    )
}

export default EditUserMedia;
