import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import avatar from '../assets/images/signUp-avatar.svg';

function SlideBanner(props) {

    const navigate = useNavigate();
    const handleNavigate = ()=> {
        navigate(props.link);
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem("token");
        setIsLoggedIn(!!token);
    }, []); 

  return (
    <>
        {/*/--- banner section ---/*/}
        <section className='home-banner main-banner' style={{backgroundImage: `url('${props.bnnerImg}')`}}>
            <div className={`${props.bannerClass} h-100-p`}>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12} lg={12} className='mx-auto'>
                            <div className='home-banner-wrapper text-center'>
                            
                                <h2 className={'typing-demo'}>
                                    {props.title}
                                </h2>
                                <h2 className={'typing-demo'}>
                                    {props.title2}
                                </h2>
                                <h2 className={`typing-demo`}>
                                    {props.title3}
                                </h2>
                                
                                <p className={`typing-demo`}>{props.subtitle}</p>
                                
                                <CButton 
                                    className={`explore-btn ${props.bannerSignUp}`} 
                                    onClick={handleNavigate}
                                >
                                    <img className='signUp-avatar' src={avatar} alt='avatar'/>
                                    <span className='btn-content'>{props.btnContent}</span>
                                </CButton>
                                
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        </section>
    </>
  )
}

export default SlideBanner;
