import React, { useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';

function PrivacyPolicy() {

    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            {/* <section className='home-banner video-hub-banner'>
                <div className='video-banner-layer'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='home-banner-wrapper text-center'>
                                    <h2>
                                        {t('privacy policy')}
                                    </h2>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </section> */}

            {/*/---- privacy policy section ----/*/}
            <section className='privacy-policy-wrapper'>
                <CContainer>
                    <CRow>
                        <CCol md={12}>
                            <div className='privacy-policy-wrapper-content w-100'>
                                <div className='privacy-policy-card'>
                                    <div className="section-header mb-5">
                                        <h2 className="section-heading">{t('Privacy')}</h2>
                                        <h3 className="section-sub-heading">{t('Policy')}</h3>
                                    </div>

                                    <div className='policy-content'>
                                        <h3>{t('Introduction')}</h3>
                                        <p>
                                            {t("Cinewoo studios Inc. ('cinewoo', 'we', 'us, or 'our') is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By accessing or using our services, you agree to the terms of this Privacy Policy.")}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Information We Collect')}</h3>
                                        <ul>
                                            <li>
                                                <span>{t('Personal Information:')} </span>
                                                {t('When you register on our platform, we may collect personal information such as your name, date of birth, email address, phone number, and billing information.')}
                                            </li>
                                            <li>
                                                <span>{t('Usage Data:')} </span>
                                                {t('We collect information about your interactions with our platform, including the content you view, the pages you visit, and the actions you take.')}
                                            </li>
                                            <li>
                                                <span>{t('Cookies and Tracking Technologies:')} </span>
                                                {t('We use cookies and similar tracking technologies to enhance your experience on our platform.')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('How We Use Your Information')}</h3>
                                        <p>{t('We may use the information we collect to:')}</p>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('Provide, operate, and maintain our services.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Improve, personalize, and expand our services.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Process your transactions and manage your orders.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Prevent and detect fraud and abuse.')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('How We Share Your Information')}</h3>
                                        <p>{t('We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:')}</p>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('With service providers who assist us in operating our platform, conducting our business, or servicing you.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('To comply with legal obligations, respond to lawful requests from public authorities, and protect our rights.')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Data Retention')}</h3>
                                        <p>{t('We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by applicable laws.')}</p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Security')}</h3>
                                        <p>{t('We implement appropriate technical and organizational measures to protect your personal information against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.')}</p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Your Rights')}</h3>
                                        <p>{t('You have the right to:')}</p>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('Access the personal information we hold about you.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Request correction of inaccurate or incomplete information.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Request deletion of your personal information.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Withdraw consent to the processing of your personal information.')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Changes to This Privacy Policy')}</h3>
                                        <p>{t('We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.')}</p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Contact Us')}</h3>
                                        <p>
                                            {t('If you have any questions about this Privacy Policy, please contact us at:')}
                                            <Link to=''>info@cinewoo.com</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default PrivacyPolicy;
