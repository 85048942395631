import React, { useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import PitchVision from '../components/PitchVision';

function PitchProfile() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*/--- Navbar ---/*/}
            <Navbar />

            {/* casting audition section */}
            <section className='casting-audition-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={10} className='mx-auto'>
                            <PitchVision />
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default PitchProfile;
