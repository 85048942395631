import React, { useEffect, useState } from 'react';
import { CRow, CCol } from '@coreui/react';


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

function SuccessStoryCard(props) {
  const formattedDate = formatDate(props.datetime);
 
  return (
    <>
      <div className='success-story-card mt-4'>
        <CRow>
            <CCol md={6}>
                <div className='success-img-wrapper'>
                    <img src={props.imgSrc} alt='success' />
                </div>
                <div className='success-card-footer'>
                    <h3 className='mt-2'>{props.title}</h3>
                    <span>by admin</span>
                </div>
            </CCol>
            <CCol md={6}>
                <div className='success-card-txt mt-3 mt-lg-0 mb-5 mb-lg-0 pb-4 pb-lg-0'>
                    <p className=''>
                      {props.description}
                    </p>
                </div>
                <div className='success-card-footer date-location-footer'>
                    <h5 className='mt-2'>Dates & Locations</h5>
                    <p>{props.location} ,{formattedDate} </p>
                </div>
            </CCol>
        </CRow>
      </div>
    </>
  )
}

export default SuccessStoryCard;
